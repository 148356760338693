<template>
  <div>
    <validation-observer ref="simple">
      <b-form>
        <b-card-code title="  تعديل  متطوع">
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-row>

              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="name"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>  اسم المتطوع</label>
                    <b-form-input
                      id="input-default"
                      v-model="name"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="file"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>  الرقم </label>
                    <b-form-input
                      id="input-default"
                      v-model="number"
                      type="number"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
            <!-- <b-row>
              
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="area"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>المنطقة </label>
                    <b-form-textarea
                      id="input-default"
                      v-model="area"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="notes"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>العنوان </label>
                    <b-form-textarea
                      id="input-default"
                      v-model="address"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row> -->
            <h3>العنوان:   </h3>
          <br />
            <b-row>
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                <b-form-group label-for="townbycity">
                <label> المنطقة</label>
                <v-select
                  v-model="city"
                  :reduce="(val) => val.value"
                  :options="optionCity"
                  @input="getoptionTArea(city)"
                />
              </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                <b-form-group label-for="townbyvillage">
                <label> الناحية</label>
                <v-select
                  v-model="town"
                  :reduce="(val) => val.value"
                  :options="optionTVillages"
                  @input="getoptionTown(town)"
                />
              </b-form-group>
           
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                <b-form-group label-for="newtown">
                <label> الحي</label> 
                <v-select
                  id="input-default"
                  v-model="neighborhood_id"
                  :reduce="(val) => val.value"
                  :options="optionTown"
                  @input="getoptionStreet(neighborhood_id)"
                />
              </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                 
                >
                <b-form-group label-for="newtown">
                <label> تفاصيل الحي</label>
                <v-select
                  v-model="street_id"
                  :reduce="(val) => val.value"
                  :options="optionStreet"
                  
                />
              </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
      
            </b-row>
            
            <b-row>
              <b-col
                md="12"
                xl="12"
              >
                <b-button
                  variant="purple"
                  @click="updateVolunteer"
                >
                  
                  <span class="align-middle"> تعديل المتطوع</span>
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-col
      md="4"
      xl="2"
    >
      <b-button
        variant="purple "
        @click="$router.go(-1)"
      >
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
  </div>
</template>

<script>


import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

import {
  BRow,
  BCol,
  BFormGroup,

  BFormInput,
  BFormTextarea,

  BButton,
  BForm,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from "@validations";

export default {

  components: {
    required,
    BFormInvalidFeedback,
    vSelect,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,

    BFormGroup,
  
    BFormTextarea,
  
    BRow,

    BCol,
  },

  data() {
    return {
      number: '',
      address: '',
      name: '',

      area: '',
      neighborhood_id:'',
      street_id:'',
      city:'',
      town:'',
      optionCity:[],
      optionTown: [],
      optionTVillages: [],
      optionStreet : []
     
    
    }
  },
  watch: {
    'city': function () {
      this.optionTVillages = []
      const url = `/api/v1/cities/${this.city}/towns`
     
      this.$http.get(url).then(response => {
        const country = response.data.data
        country.forEach(el => {
          // console.log(el)
          this.optionTVillages.push({ value: el.id, label: el.name })
        })
      })
     
    },
    'town': function () {
      this.optionTown= []
      const url = `/api/v1/towns/${this.town}/neighborhoods`
     
      this.$http.get(url).then(response => {
       //  console.log(response.data.data)
         const country = response.data.data
        country.forEach(el => {
          // console.log(el)
          this.optionTown.push({ value: el.id, label: el.name })
        })
      })
     
    },
    'neighborhood_id': function () {
      this.optionStreet= []
      const url = `/api/v1/neighborhoods/${this.neighborhood_id}/streets`
     
      this.$http.get(url).then(response => {
       //  console.log(response.data.data)
         const country = response.data.data
        country.forEach(el => {
          // console.log(el)
          this.optionStreet.push({ value: el.id, label: el.name })
        })
      })
     
    },

  },


  created() {
    this.getvolunteer(),
    this. getoptionTCity(8)

  },

  methods: {
    getvolunteer() {
      this.$http.get(`/api/v1/volunteer/${this.$route.params.id}`).then(res => {
    //  console.log(res.data);
        this.town = res.data.data.town.id
        this.city = res.data.data.city.id
        this.neighborhood_id = res.data.data.neighborhood.id
        this.street_id = res.data.data.street.id
        this.name = res.data.data.name
        this.number = res.data.data.number
       
      })
    },

    
    getoptionTCity(id) {
      this.optionCity = []
      const url = `/api/v1/governorates/${id}/cities`
      this.$http.get(url).then(res => {
        const country = res.data.data
        country.forEach(el => {
          this.optionCity.push({ value: el.id, label: el.name })
        })
      })
    },
    getoptionStreet(id) {
      this.optionStreet = []
      const url = `/api/v1/neighborhoods/${id}/streets`
      this.$http.get(url).then(response => {
        const towns = response.data.data

        towns.forEach(el => {
          this.optionStreet.push({ label: el.name, value: el.id })
        })
      })
    },
    updateVolunteer() {
      return new Promise((resolve, reject) => {
        this.$refs.simple
          .validate()
          .then(success => {
            if (success) {
              const data = {
                name: this.name,
                number: this.number,
                city_id: this.city,
                town_id: this.town,
                neighborhood_id: this.neighborhood_id,
                street_id: this.street_id
              }

              // console.log("formData",formData)
              this.$http.put(`/api/v1/volunteer/${this.$route.params.id}`, data).then(res => {
                // console.log(res);
                this.getvolunteers()
                this.$swal({
                  title: '',
                  text: 'تمت تعديل المتطوع بنجاح',
                  icon: 'success',
                  confirmButtonText: 'موافق',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
            }
          })
      })
    },

    getvolunteers() {
      this.$http.get('/api/v1/volunteer').then(res => {
        // console.log('volunteer', res.data.data)
        this.rows = res.data.data
      })
    },

    //
  },
}
</script>

  <style scoped>
  label {
    color: rgba(112, 192, 203, 255);
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .btn-purple {
    color: white;
    background-color: #7e7a9f;
    margin-top: 25px;
  }

  span {
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .align-left {
    left: 50px;
  }
  .but {
    top: 40px;
  }
  </style>
